.result-container-risk {  /* autoprefixer: off */
  height: -webkit-fill-available;
  margin-bottom: 0px;
  padding-bottom: 0;
  overflow-y: hidden;
}
.ui-tabs {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 1px 2px 0 #11000000, 0 1px 3px 1px rgba(60, 64, 67, 0.149);
          box-shadow: 0 1px 2px 0 #11000000, 0 1px 3px 1px rgba(60, 64, 67, 0.149);
}
.ui-tab.configPrimaryColorClass {
  cursor: unset;
}
.ui-tab {
  cursor: pointer;
  width: 50%;
  color: #8b8b8b;
  font-size: 15px;
  text-align: center;
  border-right: 1px solid #f2f2f2;
  padding: 10px 0px 10px 0px;
}
.ui-tab2 {
  cursor: pointer;
  width: 20%;
  text-align: center;
  border-right: 1px solid #f2f2f2;
  padding: 10px 0px 10px 0px;
  color: #8b8b8b;
  font-size: 14px;
}
.ui-tab2.configPrimaryColorClass {
  cursor: unset;
}
.funds-card {
  /* box-shadow: 0 1px 2px 0 #11000000, 0 1px 3px 1px rgba(60,64,67,0.149); */
}
.meter-img {
  text-align: center;
  margin: 50px 0;
}
.meter-img img {
  width: 70%;
}
.risk-result-error {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 100%;
  margin-top: 50px;
}
.risk-meter-desc {
  text-align: center;
}
.risk-meter-desc .risk-meter-desc-title {
  color: #0a1d32;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}
.risk-meter-desc .risk-meter-desc-content {
  color: #0a1d32;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
}
.fund-details {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 10px 10px 10px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 1px 2px 0 #11000000, 0 1px 3px 1px rgba(60, 64, 67, 0.149);
          box-shadow: 0 1px 2px 0 #11000000, 0 1px 3px 1px rgba(60, 64, 67, 0.149);
}
.fund-details-head {
  color: #4a4a4a;
  font-weight: 600;
  font-size: 16px;
}
.fund-details-below {
  font-size: 14px;
  color: #525252;
  margin-top: 10px;
}
.recommendation-containerWrapper {
  background-color: #f2f2f2;
  /* autoprefixer: off */
  /* height: -webkit-fill-available; */
  height: 100%;
  padding: 10px 7px 10px 7px;
  /* overflow-y: hidden; */
  /* margin-bottom: 0px !important; */
}
.recommendation-container {
  background-color: #f2f2f2;
  /* autoprefixer: off */
  /* height: 100%; */
  overflow-y: scroll;
  padding: 10px 0px 14px 0px;
  /* margin-bottom: 20px !important; */
}
.question-container {
  margin-top: 20px;
}

.risk_profiler-intro {
  margin-top: 4px;
}
.risk_profiler-intro-heading {
  font-weight: 700;
  color: #0a1d32;
  font-size: 20px;
  margin: 13px 0 0 0;
  line-height: 23.7px;
}
.risk_profiler-intro-image {
  margin: 20px 0 0 2px;
  width: 100%;
}
.risk_profiler-intro-des {
  margin: 20px 4px 0 4px;
  color: #767e86;
  line-height: 20px;
  font-size: 13px;
}
.risk_profiler-benefit-outer {
  margin: 30px 4px 0 2px;
}
.risk_profiler-benefit-heading {
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  color: #0a1d32;
  margin: 0;
}
.risk_profiler-benefitList {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25.72px;
}
.risk_profiler-benefitList-img {
  margin-right: 20px;
}
.risk_profiler-benefitList-des {
  margin: 0;
  color: #767e86;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
}
.risk-question {
  font-size: 16px !important;
  line-height: 1.5 !important;
  margin-bottom: 10px;
}
