.biller-margin {
  margin-top: 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px 0px 10px 0px;
    padding-bottom: 3px;
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 400;
}
.result-container-isip {
  background: #f9f9f9;
  /* autoprefixer: off */
  height: -webkit-fill-available; 
  margin-bottom: 0px;
  padding-bottom: 0;
  /* overflow-y: scroll; */
}
.result-container2 {
  margin-bottom: 20px;
  padding-bottom: 30px;
  overflow-y: scroll;
}
.biller-details-tile {
  padding: 10px;
    background: white;
    border-radius: 6px;
    -webkit-box-shadow: 0px 2px 6px 0px rgba(102,120,121,0.25);
            box-shadow: 0px 2px 6px 0px rgba(102,120,121,0.25);
}
.biller-bankname {
  color: rgb(135, 135, 135);
    font-size: 14px;
    font-weight: 600;
    line-height: 2.1;
    text-transform: uppercase;
}
.biller-accountnumber {
  color: #9e9e9e;
    line-height: 19px;
    font-weight: 400;
}
.biller-accountnumber2 {
  color: #9e9e9e;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 7px;
}
.biller-dot {
  height: 5px;
    width: 5px;
    margin-right: 20px;
    border-radius: 50%;
    border: 2px solid #b4b4b4;
    margin-top: 10px;
}
.biller-about-points {
  font-size: 15px;
    color: rgb(135, 135, 135);
    margin: 10px 0px 0px 0px;
    display: -webkit-box;
}
.biller-steps-title {
    font-size: 20px;
    text-align: center;
    color: black;
}
.biller-steps-small-title {
  text-align: left;
    margin: 3px 0 0px 0;
    color: rgb(63, 61, 61);
    font-weight: 400;
    font-size: 14px;
}
.biller-steps-footer-title {
  margin: 20px 0px 0 0;
    text-align: left;
    color: rgb(135, 135, 135);
    font-weight: 400;
    font-size: 13px;
}
.step-tiles-title {
  font-size: 15px;
    margin: 20px 0 10px 0px;
    color: black;
    font-weight: 500;
}
.steps-tiles-image-tag {
  border-radius: 3px;
  -webkit-box-shadow: 0px 2px 6px 0px rgba(102,120,121,0.25);
          box-shadow: 0px 2px 6px 0px rgba(102,120,121,0.25);
  border: 1px solid beige;
  padding: 3px;
  margin-bottom: 10px;
}
.biller-id-copy {
  float: right;
    position: relative;
    right: 3px;
    font-size: 12px;
    border: 1px solid beige;
    padding: 0px 10px 0 10px;
    border-radius: 4px;
    color: #000!important;
    background-color: #f1f1f1!important;
    -webkit-box-shadow: 0px 2px 6px 0px rgba(102,120,121,0.25);
            box-shadow: 0px 2px 6px 0px rgba(102,120,121,0.25);
}
.copy-item-billder-details {
  -ms-flex-pack: justify;
      justify-content: space-between;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #efefef;
    margin: 0 5px 0 0px;
    position: relative;
    left: 5px;
    padding: 1px 6px 1px 6px;
    border-radius: 4px;
}
.biller-netbank-tile {
  display: -ms-flexbox;
  display: flex;
}
.biller-netbank-link {
  border: 1px solid #cccccc;
    font-size: 12px;
    color: #878787;
    padding: 5px 10px 5px 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    height: 25px;
    overflow-y: -webkit-paged-y;
    -o-text-overflow: clip;
       text-overflow: clip;
    white-space: initial;
}
.biller-netbnak-copy {
  font-size: 12px;
    color: white;
    font-weight: 600;
    padding: 5px 10px 5px 10px;
    background: var(--secondary);
    margin-left: 2px;
    border-top-right-radius: 4px;
    cursor: pointer;
    border-bottom-right-radius: 4px;
}
.biller-netbnak-copy:hover{
  background-color: var(--on-hover);
}
