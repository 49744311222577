html, body, #root .ReferralContainerWrapper {
  height: 100%;
  
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif !important;
  font-size: 14px;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  height: 100vh;
}
html {
  margin: 0;
  padding: 0;
  /* height: 100vh; */
}

*:focus {
  outline: none;
}

input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.Card {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px;
  -webkit-box-shadow: 0px 1.5px 3px 0px #c0c0c0;
          box-shadow: 0px 1.5px 3px 0px #c0c0c0;
}

.Card.nopadding {
  padding: 0.1px;
}

.pad15 {
  padding: 15px;
}

.pad20 {
  padding: 20px;
}

.pad25 {
  padding: 25px;
}

.padLR15 {
  padding: 0 15px;
}

.GreyBackground {
  background-color: #efefef;
}

.DarkGreyBackground {
  background-color: #878787;
}

.BoldText {
  font-weight: 700;
}

.NotFound {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
